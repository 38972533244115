import type {
  LOCALE_CODES,
  MODE_CODES,
  OPCO_CODES,
} from '@channel/shared-components';
import { NavLinkProperties } from '../../components/navigation/nav/nav.types';
import { SearchBarProperties } from '../search-bar/search-bar.types';

export type HeaderProperties = {
  logo: {
    href: string;
    src: string;
    alt: string;
  };
  smallLogo: {
    href: string;
    src: string | null;
    alt: string;
  };
  loginLabel: string;
  membershipLabel: string;
  myAccount: {
    href: string;
  };
  membershipDetails: {
    totalAviosLabel: string;
    aviosBalance: string;
    closeDetailsButtonTitle: string;
    missingAviosCtaLabel: string;
    missingAviosCtaUrl: string;
    myAccountCtaLabel: string;
    myAccountCtaUrl: string;
    logoutCtaLabel: string;
    logoutCtaUrl: string;
    membershipNumberLabel: string;
    showQrCodeLabel: string;
    qrCodeEnabled: boolean;
  };
  navigation: {
    static: NavLinkProperties[];
    scrollable: NavLinkProperties[];
  };
  children?: JSX.Element | JSX.Element[];
  sharedHeaderProps: {
    mode: MODE_CODES;
    opco: OPCO_CODES;
    locale: LOCALE_CODES;
    searchBar: SearchBarProperties;
    channelsClubUrl: string;
  };
};

export type HeaderQueryResponse = {
  headerCollection: {
    items: Array<{
      programLogo: {
        url: string;
        title: string;
      };
      programLogoSmallScreen: {
        url: string;
        title: string;
      };
      navigationCollection: {
        items: Array<{
          label: string;
          urlPath: string;
          isHighlighted: boolean;
        }>;
      };
      membershipDetails: {
        totalAviosLabel: string;
        closeDetailsButtonTitle: string;
        missingAviosCta: {
          label: string;
          url: string;
        };
        myAccountCta: {
          label: string;
          url: string;
        };
        auth0LogoutCta: {
          label: string;
          url: string;
        };
        membershipNumberLabel: string;
        ctaListCollection: {
          items: CtaStructure[];
        };
      };
    }>;
  };
  microcopyCollection: {
    items: Array<{
      key: string;
      value: string;
    }>;
  };
};

export type CtaStructure = {
  developerRef: string | null;
  label: string;
  url: string;
};

export enum MEMBER_DETAILS_CTAS {
  SHOW_QR_CODE = 'show-qr-code',
}
