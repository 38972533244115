import { GetServerSidePropsContext } from 'next';
import type { MODE_CODES } from '@channel/shared-components';
import getEnvironmentVariable from '@/utils/get-environment-variable';
import {
  FOOTER_COLLECTION,
  FooterCollectionItem,
  FooterProperties,
  FooterQueryResponse,
} from './footer.types';
import ContentfulProvider from '../../providers/contentful';
import { footerQuery } from './footer.query';
import { buildUrlPath } from '../../utils/build-url-path';
import {
  sharedComponentsLocalesMap,
  sharedComponentsOpcoMap,
} from '../../constants';

export const footerHandler = async ({
  req: { market },
}: GetServerSidePropsContext): Promise<FooterProperties> => {
  const contentfulResponse = await new ContentfulProvider(
    market,
  ).makeContentfulRequest<FooterQueryResponse>({
    query: footerQuery,
  });

  const items = [
    ...contentfulResponse.data[FOOTER_COLLECTION.WithProgramAndCountry].items,
    ...contentfulResponse.data[FOOTER_COLLECTION.WithProgram].items,
    ...contentfulResponse.data[FOOTER_COLLECTION.Default].items,
  ];

  const footerLabelsCollection: { [key: string]: string } = {};
  for (const item of contentfulResponse.data.microcopyCollection.items) {
    footerLabelsCollection[item.key] = item.value;
  }

  const formattedFooterCollection = {} as FooterCollectionItem;

  for (const item of items) {
    for (const [key, value] of Object.entries(item)) {
      if (
        value !== null &&
        value !== undefined &&
        !formattedFooterCollection[key as keyof FooterCollectionItem]
      ) {
        (formattedFooterCollection as Record<string, unknown>)[key] = value;
      }
    }

    if (
      item.navigationCollection &&
      item.navigationCollection.items.length > 0 &&
      !formattedFooterCollection.navigationCollection
    ) {
      formattedFooterCollection.navigationCollection = {
        items: item.navigationCollection.items,
      };
    }
  }

  const {
    aviosLogo,
    copyright,
    aviosText,
    aviosLink,
    navigationCollection: { items: navItems },
  } = formattedFooterCollection;

  const channelsClubUrl = getEnvironmentVariable('CHANNELS_CLUB_BASE_URL');

  return {
    navigation: navItems.map(({ urlPath, label }) => ({
      destination: buildUrlPath(urlPath),
      children: label,
    })),
    aviosLogo: {
      alt: aviosLogo?.title,
      src: aviosLogo?.url,
    },
    aviosText,
    aviosLink,
    copyright,
    availableLocales: market.availableLocales,
    currentLocale: market.locale,
    footerLabelsCollection,
    sharedFooterProps: {
      mode: 'collect' as MODE_CODES,
      opco: sharedComponentsOpcoMap[market.opCoId],
      locale: sharedComponentsLocalesMap[market.locale],
      markets: {
        enabled: market.availableLocales.length > 1,
      },
      channelsClubUrl,
    },
  };
};
