import { OPCO_ID_EXTERNAL_SERVICE } from '@/constants';
import { Market } from '../../models/market/market.types';
import { B2BToken } from '../../types';
import Fetch from '../../utils/fetch';
import getCBRConfig from './cbr.config';
import { Query, Response, Config } from './cbr.types';

class CBRProvider extends Fetch {
  private config: Config;

  constructor(
    private market: Market,
    authenticationToken: B2BToken,
    useBasicAuth = false,
  ) {
    const cbrConfig = getCBRConfig();
    super(cbrConfig.API_URL);
    this.config = cbrConfig;

    this.setHeaders(authenticationToken, useBasicAuth);
  }

  private setHeaders(b2bToken: B2BToken, useBasicAuth = false) {
    this.headers = {
      'CBR-PROGRAM': this.config.PROGRAM,
      'content-type': 'application/json',
      Authorization: `${useBasicAuth ? 'Basic' : 'Bearer'} ${b2bToken}`,
      api_key: this.config.API_KEY,
    };
  }

  public async makeCBRRequest<T = Record<string, unknown>>({
    query,
    variables,
    includeDefaultVariables = true,
    headers,
  }: Query<Record<string, unknown>>) {
    this.headers = { ...this.headers, ...headers };
    const defaultVariables = includeDefaultVariables
      ? {
          opCoId: OPCO_ID_EXTERNAL_SERVICE[this.market.opCoId],
          countryCode: this.market.countryCode,
        }
      : {};

    return this.post<Response<T>>('', {
      bodyJSON: {
        query,
        variables: {
          ...variables,
          ...defaultVariables,
        },
      },
    });
  }

  public makeCBRMutation<T = Record<string, unknown>>({
    query,
    variables,
    headers,
  }: Query<Record<string, unknown>>) {
    this.headers = { ...this.headers, ...headers };

    return this.post<Response<T>>('', {
      bodyJSON: {
        query,
        variables,
      },
    });
  }
}

export default CBRProvider;
