import { NextApiRequestInjected, NextApiResponse } from 'next';
import { AVIOS_HOME, OPCO_ID_EXTERNAL_SERVICE } from '@/constants';
import { OPCO_ID } from '@/models/market/market.types';
import { featureTogglesHandler } from '@/utils/shared-handler/feature-toggle-handler';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import { getSession } from '../../providers/auth0';
import CollinsonProvider from '../../providers/collinson';
import extractUserData from '../extract-user-data';
import User from '../../models/user';
import { getDaysSince } from '../get-days-since';
import { NOTIFICATIONS } from '../../modules/notifications/notifications.types';
import { getCollinsonUser } from '../../providers/collinson/collinson.utils';
import { isAviosOpco } from '../opco-utils';

const handleInvalidUser = (
  request: NextApiRequestInjected,
  response: NextApiResponse,
) => {
  response.setHeader(
    'Set-Cookie',
    `${NOTIFICATIONS.ERROR_INVALID_ACCOUNT}=TRUE;Path=${
      isAviosOpco(request.market.opCoId) ? AVIOS_HOME : '/'
    }`,
  );
  response.redirect('/api/auth/logout');
};

export async function getUserInfoHandler(
  request: NextApiRequestInjected,
  response: NextApiResponse,
) {
  const { market } = request;
  const session = await getSession({
    market,
    requestResponse: [request, response],
  });
  if (!session?.user) return { isLoggedIn: false };

  const userDataObject = extractUserData(session);
  const collinson = new CollinsonProvider(market);
  const collinsonUser = await getCollinsonUser(session, market);
  const featureToggleList = await featureTogglesHandler(market);
  const { membershipId } = userDataObject;

  const parameters = {
    membershipId: String(membershipId),
    programId: OPCO_ID_EXTERNAL_SERVICE[market.opCoId],
    showHouseHoldBalance: featureToggleList.includes(
      FEATURE_TOGGLE_LIST.TEMP_CONSOLIDATED_HOUSEHOLD_AVIOS,
    ),
    market,
  };

  const lastPurchasesPromise = collinsonUser
    ? collinson.getPurchaseHistory(collinsonUser.id, { limit: '1' })
    : Promise.resolve({ data: [] });

  const userBalancePromise = User.getAviosBalance(parameters);

  const [lastPurchases, userBalance] = await Promise.all([
    lastPurchasesPromise,
    userBalancePromise,
  ]);

  const lastPurchaseDate = lastPurchases.data?.[0]?.date ?? '';

  // Invalid user
  switch (market.opCoId) {
    case OPCO_ID.BAEC: {
      if (membershipId?.startsWith('OWVE')) {
        handleInvalidUser(request, response);
      }
      break;
    }
    case OPCO_ID.EIC: {
      if (membershipId?.length === 24) {
        handleInvalidUser(request, response);
      }
      break;
    }
    default: {
      break;
    }
  }

  return {
    isLoggedIn: true,
    membershipId,
    balance: userBalance,
    daysSinceLastPurchase: getDaysSince(lastPurchaseDate),
  };
}
