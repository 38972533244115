import { toggleModal } from '@/components/utilities/modal';
import { AVIOS_PREFIX, HOTJAR_ID } from '@/constants';
import ClientSideMarketContext from '@/context/client-side-market';
import FeatureTogglesContext from '@/context/feature-toggles';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import SharedComponentsProvider from '@/context/shared-components/shared-components-provider';
import { DESIGN_TOKENS_OPCO_ID } from '@/models/market/market.types';
import CookieBanner, { cookieBannerHandler } from '@/modules/cookie-banner';
import DownloadAppPrompt, {
  downloadAppPromptHandler,
} from '@/modules/download-app-prompt';
import DownloadRewardsAppModal from '@/modules/download-rewards-app/download-rewards-app';
import { downloadRewardsAppHandler } from '@/modules/download-rewards-app/download-rewards-app.handler';
import Footer, { footerHandler } from '@/modules/footer';
import Header, { headerHandler } from '@/modules/header';
import HotJarWidget from '@/modules/hotjar-feedback-widget';
import Loading from '@/modules/loading-screen';
import LocaleModal, { LocaleModalProperties } from '@/modules/locale-modal';
import { localeModalHandler } from '@/modules/locale-modal/locale-modal.handler';
import MaintenanceContent, {
  maintenanceContentPageHandler,
} from '@/modules/maintenance';
import Notifications, { notificationsHandler } from '@/modules/notifications';
import PromotionalModal, {
  promotionalModalHandler,
} from '@/modules/promotional-modal';
import QrCodeModal from '@/modules/qr-code-modal';
import { qrCodeModalHandler } from '@/modules/qr-code-modal/qr-code-modal.handler';
import SearchBar, { searchBarHandler } from '@/modules/search-bar';
import TrackClicks from '@/modules/track-clicks';
import { refreshOutdatedTokens, UserProvider } from '@/providers/auth0';
import {
  shouldRunSilentLoginForAviosOpco,
  shouldRunSilentLoginForNonAviosOpco,
  shouldRunSilentLoginForPath,
  shouldRunSilentLoginForUserAgent,
} from '@/providers/auth0/auth0.utils';
import { COOKIES } from '@/types';
import fetcher from '@/utils/fetcher';
import getEnvironmentVariable from '@/utils/get-environment-variable';
import logger, { formatErrorForLogging } from '@/utils/logger';
import { isAviosOpco } from '@/utils/opco-utils';
import { featureTogglesHandler } from '@/utils/shared-handler/feature-toggle-handler';
import { getUserInfoHandler } from '@/utils/shared-handler/get-user-info-handler';
import { useMetrics } from '@/utils/use-metrics/use-metrics';
import '@alto-avios/alto-tokens/web-ssr/avios/dark.css';
import '@alto-avios/alto-tokens/web-ssr/avios/light.css';
import '@channel/shared-header-footer/style.css';
import { Response } from 'express';
import Cookies from 'js-cookie';
import {
  GetServerSidePropsContext,
  NextApiRequestInjected,
  NextApiResponse,
} from 'next';
import App, { AppContext } from 'next/app';
import { ErrorBoundary } from 'next/dist/client/components/error-boundary';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { Button } from '@alto-avios/alto-ui';
import type { MyAppProperties } from './_app.types';

const MAX_SCROLL_TO_HASH_ATTEMPTS = 15;

export function scrollToHash() {
  const hashId = window.location.hash;
  if (hashId) {
    const element = document.querySelector(`#${hashId.slice(1)}`);

    if (element) {
      const elementTop = element.getBoundingClientRect().top;
      const topPosition = elementTop + window.scrollY;

      window.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      });
    }
  }
}

const ErrorComponent = () => <p>Error on this page</p>;

const MyApp = ({
  Component,
  pageProps,
  programData,
  cookieBannerProperties,
  maintenancePageProperties,
  featureToggles = [],
  application_environment,
  downloadRewardsAppProperties,
  qrCodeModalProperties,
  userProperties,
  promotionalModalProperties,
  downloadAppPromptProperties,
  notificationsProperties,
  localeModalProperties,
  headerProperties,
  searchBarProperties,
  footerProperties,
  clientSideMarket,
  aviosCMSToken,
  sharedHeaderFooterEnvironment,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
MyAppProperties) => {
  const [showRewardsAppModal, setShowRewardsAppModal] =
    useState<boolean>(false);
  const [showDownloadAppPrompt, setShowDownloadAppPrompt] =
    useState<boolean>(false);
  const [showLocaleModal, setShowLocaleModal] = useState<boolean>(false);

  const disableCookieBanner = featureToggles?.includes(
    FEATURE_TOGGLE_LIST.COOKIEDISABLED,
  );

  const featureToggleTemporaryLocaleModal = featureToggles?.includes(
    FEATURE_TOGGLE_LIST.TEMP_LOCALE_MODAL,
  );
  const showLocaleModalFeature =
    featureToggleTemporaryLocaleModal &&
    showLocaleModal &&
    localeModalProperties;

  const downloadRewardsAppModalEnabled =
    featureToggles?.includes(FEATURE_TOGGLE_LIST.DOWNLOAD_REWARDS_APP_MODAL) &&
    (isAndroid || isIOS) &&
    showRewardsAppModal;

  const downloadAppPromptEnabled =
    featureToggles?.includes(FEATURE_TOGGLE_LIST.DOWNLOAD_APP_PROMPT) &&
    (isAndroid || isIOS) &&
    showDownloadAppPrompt &&
    !Cookies.get(COOKIES.DOWNLOAD_APP_PROMPT);

  const qrCodeEnabled =
    featureToggles.includes(FEATURE_TOGGLE_LIST.SHOW_QR_CODE) &&
    qrCodeModalProperties;

  const trackClickFeature = featureToggles.includes(
    FEATURE_TOGGLE_LIST.TEMP_TRACK_CLICKS,
  );

  const hjid = HOTJAR_ID[application_environment] ?? '';

  const { isLoggedIn, membershipId, balance, daysSinceLastPurchase } =
    userProperties;

  useMetrics({
    programData,
    isLoggedIn,
    membershipId,
    balance,
    daysSinceLastPurchase,
    applicationEnvironment: application_environment,
  });

  useEffect(() => {
    setTimeout(() => {
      setShowRewardsAppModal(true);
      setShowDownloadAppPrompt(true);
    }, 100);
  });

  // Check a better way to do the scrollToHash - AEM-4785
  useEffect(() => {
    const hashId = window.location.hash;

    let x = 0;
    const intervalScroll = setInterval(() => {
      scrollToHash();
      x += 1;

      if (
        !hashId ||
        document.querySelector(`#${hashId.slice(1)}`) ||
        x > MAX_SCROLL_TO_HASH_ATTEMPTS
      ) {
        window.clearInterval(intervalScroll);
      }
    }, 200);
  });

  useEffect(() => {
    const firstTimeUser = Cookies.get(COOKIES.FIRST_TIME_USER);
    if (
      firstTimeUser &&
      featureToggleTemporaryLocaleModal &&
      localeModalProperties &&
      localeModalProperties.availableLocales.length > 1
    ) {
      setShowLocaleModal(true);
      toggleModal('locale-modal'); // this will disable the horizontal scrollbar
    }
  }, [
    featureToggleTemporaryLocaleModal,
    showLocaleModal,
    localeModalProperties,
  ]);

  const closeLocaleModal = () => {
    Cookies.remove(COOKIES.FIRST_TIME_USER);
    setShowLocaleModal(false);
  };

  const closePromptHandler = () => {
    setShowDownloadAppPrompt(false);
    Cookies.set(COOKIES.DOWNLOAD_APP_PROMPT, 'shown', { expires: 7 });
  };

  const DesignTokens = dynamic(
    async () => {
      const { opCoId } = programData;

      const sharedComponentsModules = await import(
        `@alto-avios/alto-tokens/web-ssr/${DESIGN_TOKENS_OPCO_ID[opCoId]}/light.css`
      );
      return sharedComponentsModules.DesignTokens;
    },
    {
      ssr: false,
    },
  );

  return maintenancePageProperties?.isActive ? (
    <MaintenanceContent {...maintenancePageProperties} />
  ) : (
    <ClientSideMarketContext.Provider value={clientSideMarket}>
      <FeatureTogglesContext.Provider value={featureToggles}>
        <UserProvider fetcher={fetcher}>
          <SharedComponentsProvider
            cms={{
              aviosCMSToken,
              sharedHeaderFooterEnvironment,
            }}
            opCoId={clientSideMarket?.opCoId}
          >
            <Loading />
            {/* the following is needed to prevent NextJs from removing styles it
            thinks are not needed https://aviosgroup.atlassian.net/browse/AEM-5017 
            This was tried, but it still removes styles when navigating whithin the same dynamic route
            https://github.com/vercel/next.js/discussions/64658#discussioncomment-9142291 */}
            <div style={{ display: 'none' }}>
              <Button />
            </div>
            {trackClickFeature && <TrackClicks />}
            <DesignTokens />
            <Header {...headerProperties}>
              <SearchBar {...searchBarProperties} />
            </Header>
            <main>
              <ErrorBoundary errorComponent={ErrorComponent}>
                <Component {...pageProps} />
              </ErrorBoundary>
            </main>
            <Footer {...footerProperties} />
            {notificationsProperties && (
              <Notifications {...notificationsProperties} />
            )}
            {!disableCookieBanner && (
              <CookieBanner {...cookieBannerProperties} />
            )}
            {hjid && <HotJarWidget hjid={hjid} />}
            {downloadRewardsAppModalEnabled && (
              <DownloadRewardsAppModal {...downloadRewardsAppProperties} />
            )}
            {qrCodeEnabled && <QrCodeModal {...qrCodeModalProperties} />}
            {promotionalModalProperties && !showLocaleModal && (
              <PromotionalModal {...promotionalModalProperties} />
            )}
            {downloadAppPromptEnabled && downloadAppPromptProperties && (
              <DownloadAppPrompt
                {...downloadAppPromptProperties}
                closePrompt={closePromptHandler}
              />
            )}
            {showLocaleModalFeature && (
              <LocaleModal
                {...(localeModalProperties as LocaleModalProperties)}
                label="locale-modal"
                onCloseFunction={closeLocaleModal}
              />
            )}
          </SharedComponentsProvider>
        </UserProvider>
      </FeatureTogglesContext.Provider>
    </ClientSideMarketContext.Provider>
  );
};

MyApp.getInitialProps = async (context: AppContext) => {
  try {
    const initialProperties = await App.getInitialProps(context);
    const request = context.ctx.req as NextApiRequestInjected;
    const response = context.ctx.res as NextApiResponse & Response;
    const {
      application_environment,
      url: path,
      market,
      cookies,
      headers,
    } = request;
    const programData = {
      Iso: market?.countryCode,
      programmeCode: market?.programId,
      countryID: market?.countryId,
      opCoId: market.opCoId,
    };

    const session = await refreshOutdatedTokens(market, [request, response]);

    const newContext = {
      req: {
        ...context.ctx.req,
        market,
      },
      res: context.ctx.res,
    } as GetServerSidePropsContext;

    const [
      headerProperties,
      searchBarProperties,
      footerProperties,
      featureToggles,
      maintenancePageProperties,
      cookieBannerProperties,
      downloadRewardsAppProperties,
      qrCodeModalProperties,
      userProperties,
      promotionalModalProperties,
      downloadAppPromptProperties,
      notificationsProperties,
      localeModalProperties,
    ] = await Promise.all([
      headerHandler(newContext),
      searchBarHandler(newContext),
      footerHandler(newContext),
      featureTogglesHandler(market),
      maintenanceContentPageHandler(market),
      cookieBannerHandler(market),
      downloadRewardsAppHandler(market),
      qrCodeModalHandler(request, response),
      getUserInfoHandler(request, response),
      promotionalModalHandler(market),
      downloadAppPromptHandler(market),
      notificationsHandler(market),
      localeModalHandler(market),
    ]);

    // TODO: remove this code with AEM-4141
    if (cookies[COOKIES.HAS_DONE_SILENT_LOGIN] === 'true') {
      response.setHeader('Set-Cookie', [
        `${COOKIES.HAS_DONE_SILENT_LOGIN}=true;Path=/;Max-Age=0`,
        `${COOKIES.HAS_DONE_SILENT_LOGIN_V2}=true;Path=/;Max-Age=0`,
      ]);
    }

    if (
      shouldRunSilentLoginForPath(path) &&
      shouldRunSilentLoginForUserAgent(headers['user-agent']) &&
      (shouldRunSilentLoginForNonAviosOpco(request, featureToggles, session) ||
        shouldRunSilentLoginForAviosOpco(request, session))
    ) {
      logger.info('Running silent login');

      response.redirect(
        `${
          isAviosOpco(market.opCoId) ? AVIOS_PREFIX : ''
        }/api/auth/silent-login/?silentLoginReturnTo=${btoa(path ?? '/')}`,
      );
    } else {
      response.cookie(COOKIES.SH_SILENT_AUTH, 'true', { maxAge: 0 });
    }
    const AVIOS_CMS_TOKEN = getEnvironmentVariable('AVIOS_CMS_TOKEN');
    const AVIOS_HEADER_FOOTER_ENV = getEnvironmentVariable(
      'AVIOS_HEADER_FOOTER_ENV',
    );

    return {
      ...initialProperties,
      programData,
      featureToggles,
      maintenancePageProperties,
      cookieBannerProperties,
      application_environment,
      downloadRewardsAppProperties,
      qrCodeModalProperties,
      userProperties,
      promotionalModalProperties,
      downloadAppPromptProperties,
      notificationsProperties,
      localeModalProperties,
      headerProperties,
      searchBarProperties,
      footerProperties,
      clientSideMarket: market.clientSideMarket,
      aviosCMSToken: AVIOS_CMS_TOKEN,
      sharedHeaderFooterEnvironment: AVIOS_HEADER_FOOTER_ENV,
    };
  } catch (error) {
    logger.error('Error while loading app initial props', {
      error: formatErrorForLogging(error),
    });
    throw error;
  }
};

export default MyApp;
